import { Reducer } from "redux";
import { Role } from "../../types/roles";
import { setRoles } from "./actions";
import { RolesType } from "./types";

export enum RolesPhase {
  INIT,
  LOADING,
  READY
}

export interface RolesState {
  state: RolesPhase;
  list: Role[];
}

const initialState: RolesState = {
  state: RolesPhase.INIT,
    list: [],
}

const reducer: Reducer<RolesState> = (state = initialState, action) => {
  switch (action.type) {
    case RolesType.SET: {
      const {payload} = action as ReturnType<typeof setRoles>;

      return {
        state: RolesPhase.READY,
        list: payload.roles
      };
    }
    case RolesType.ERROR: {
      return {
        state: RolesPhase.INIT,
        list: [],
      };
    }
    case RolesType.LOADING: {
      return {
        state: RolesPhase.LOADING,
        list: [],
      };
    }
  }

  return state;
}

export default reducer;
