import { Reducer } from 'redux';
import { OrganizationSettingsGET } from "../../types/organization";
import { OrganizationSettingsTypes } from "./types";

const initialState: OrganizationSettingsGET = {
  name: "",
  logo: ""
};

const organizationSettingsReducer: Reducer<OrganizationSettingsGET> = (state = initialState, action) => {
  switch (action.type) {
    case OrganizationSettingsTypes.SET: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}

export default organizationSettingsReducer