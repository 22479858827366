import { useEffect } from "react";
import { useFetch } from "./fetch";

export const serverParameters: Partial<Response> = {};

const useOrganizationContext = () => {
  const {loading, data, errors} = useFetch<Response|null>("/api/server-parameters", null);

  useEffect(() => {
    if (data) {
      serverParameters.hasContext = data.hasContext;
      serverParameters.appHostTemplate = data.appHostTemplate;
      serverParameters.appHostPattern = data.appHostPattern;
    }
  }, [data]);

  return {
    loading,
    ...data,
    invalid: errors.length !== 0
  };
};

interface Response {
  appHostTemplate: string;
  appHostPattern: string;
  hasContext: boolean;
}

export default useOrganizationContext;
