import React, { FC } from "react"
import {User as UserIcon} from "react-feather"
import UserAvatar from "../../@common/avatar/UserAvatar";
import moment from "moment";
import "moment/locale/pl";
import {ChatFeedbackMessage, FeedbackMessageType} from "../../../types/feedback";

moment.locale("pl");

interface FeedbackMessageItemProps {
  message: ChatFeedbackMessage;
  type: FeedbackMessageType;
}

const FeedbackMessageItem: FC<FeedbackMessageItemProps> = ({message, type}) => {
  return (
    <div
      className={`chat ${message.type === type ? "chat-right" : "chat-left"}`}
      style={{ opacity: message.isSent ? "1" : ".5", transition: 'opacity .25s' }}
    >
      <div className="chat-avatar">
        <div className="avatar m-0">
          {message.author ? (
            <UserAvatar
              name={message.author.name!}
              surname={message.author.surname!}
              userId={message.author.userId}
            />
          ) : ( // anonymous user
            <div className="avatar avatar--anonymous">
              <UserIcon color="white"/>
            </div>
          )}
        </div>
      </div>
      <div className="chat-body">
        <div className="chat-content">
          <p>
            {message.msg}
          </p>
        </div>

        <span className="chat-content chat-date">
          { moment().diff(message.date, "days") > 3
            ? moment(message.date).utc().format("DD.MM.YY HH:mm")
            : moment(message.date).fromNow()
          }
        </span>
      </div>
    </div>
  )
}

export default FeedbackMessageItem;
