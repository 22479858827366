import React, {FC} from "react";
import {FormError} from "../../api/types";
import {Alert} from "reactstrap";
import {ThumbsDown} from "react-feather";
import translateViolation from "./MessageTranslation";
import {history} from "../../history";
import {RoutePath} from "../../router/routes/Routes.types";

interface ErrorMessageProps {
  errors: Array<FormError>;
  field?: string;
  className?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({errors, field, className}) => {
  const currentErrors = errors.filter(error => {
    if (!field) {
      return !error.property_path;
    }
    return error.property_path === field;
  });

  if (errors.some(error => error.message === "This is not a valid UUID.")) {
    history.push(RoutePath.ERROR_404)
  }

  const errorsLength = currentErrors.length;

  return (
    <Alert
      className={className}
      color="danger"
      isOpen={!!errorsLength}
    >
      {currentErrors.map((error,idx) => (
        <p
          key={idx}
          className={errorsLength === 1 ? "mb-0" : undefined}
        >
          <ThumbsDown size={16}/>
          { translateViolation(error.message) }
        </p>
      ))}
    </Alert>
  )
};

export default ErrorMessage;
