import { combineReducers } from "redux"
import customizer from "./customizer/reducer"
import session from "./session/reducer"
import user from "./user/reducer"
import organizationSettings from "./organizationSettings/reducer";
import roles from "./roles/reducer";

const rootReducer = combineReducers({
  customizer,
  session,
  user,
  roles,
  organizationSettings,
})

export default rootReducer
