import React, {FC, useCallback, useEffect, useRef, useState} from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import {useFetch} from "../../../hooks/fetch";
import {useMutation} from "../../../hooks/mutation";
import useEventSource from "../../../hooks/useEventSource";
import {
  ChatFeedbackMessage,
  Feedback,
  FeedbackMessageType,
  FeedbackStatusType,
  NewFeedbackMessageEvent
} from "../../../types/feedback";
import ErrorMessage from "../../error/ErrorMessage";
import {hasErrors} from "../../error/FormErrorMessage";
import {User} from "../../../types/users";
import Spinner from "../../@vuexy/spinner/Spinner";
import AuthorFeedbackChatHeader from "../authorFeedback/AuthorFeedbackChatHeader";
import SendMessageFeedbackChatFooter from "./SendMessageFeedbackChatFooter";
import FeedbackMessageItem from "./FeedbackMessageItem";

interface FeedbackChatProps {
  feedbackId: string;
  feedbackStatus?: FeedbackStatusType;
  type: FeedbackMessageType;
  onLoad?(author: User | null, isValidFeedbackId: boolean): void;
  onTyping?: (isNotInputEmpty: boolean) => void;
  handleNewMessageDate?(date: string): void;
}

const FeedbackChat: FC<FeedbackChatProps> = (props) => {
  const chatArea = useRef<null|HTMLElement>(null);
  const {onLoad, feedbackId} = props;
  const [author, setAuthor] = useState<User | null>(null);
  const [messages, setMessages] = useState<ChatFeedbackMessage[]>([]);

  const {data: feedback, errors: loadErrors, loading} = useFetch<Feedback>(`/api/feedbacks/${feedbackId}`, {
    feedbackId,
    createdAt: "",
    anonymous: true,
    user: null,
    status: FeedbackStatusType.PING,
    messages: [],
    lastMessageDate: "",
    rating: null
  });

  const feedbackStatus = props.feedbackStatus // updated status on statusEventHandler
    || feedback.status // initial status

  useEffect(() => {
    const messages = feedback.messages.map((m): ChatFeedbackMessage => ({
      isSent: true,
      msg: m.content,
      type: m.type,
      date: m.date,
      author: m.author,
    }));
    setMessages(messages);

    if (!loading && onLoad) {
      const author = feedback.user || null
      setAuthor(author)
      onLoad(author, !!feedback.messages.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedback]);

  const handleMessageEvent = useCallback((event: NewFeedbackMessageEvent) => {
    const message: ChatFeedbackMessage = {
      isSent: true,
      msg: event.message,
      type: event.type,
      date: event.date,
      author: event.author
    };

    setMessages((m) => props.type === event.type
      ? m.reduce((messageArr: ChatFeedbackMessage[], currentMessage: ChatFeedbackMessage) => [
        ...messageArr,
        !(!currentMessage.isSent && currentMessage.msg === message.msg)
          ? currentMessage
          : message
      ], [])
      : [...m, message]);

    if (props.handleNewMessageDate) props.handleNewMessageDate(event.date)
  }, [setMessages, props.type]);

  useEventSource({
    topics: [`/api/feedbacks/${feedbackId}`],
    onMessage: handleMessageEvent,
  });

  const {update, errors} = useMutation();

  const sendMessage = async (trimmedInputValue: string): Promise<void> => {
    const newMessage: ChatFeedbackMessage = {
      isSent: false,
      msg: trimmedInputValue,
      type: props.type,
      author: author,
    }
    setMessages(prevMessages => [...prevMessages, newMessage]);

    await update(`/api/feedbacks/${props.feedbackId}`, {content: trimmedInputValue})
  };

  const scrollToBottom = (): void => {
    const chatContainer = chatArea.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight
    }
  }

  useEffect(() => scrollToBottom(), [messages]);

  return (
    <div className="chat-application chat-widget" style={{width:'100%'}}>
      <ErrorMessage errors={loadErrors}/>
      <div className="chat-app-window">
        { props.type === FeedbackMessageType.AUTHOR && (
          <AuthorFeedbackChatHeader
            feedbackStatus={feedbackStatus}
            feedbackId={feedbackId}
            feedbackRating={feedback.rating}
          />
        )}

        {hasErrors(loadErrors, "feedbackId")
          ? (
            <div className="user-chats widget-user-chat">
              Wybrany wątek nie istnieje
            </div>
          )
          : <>
            { loading
              ? <Spinner className="user-chats widget-user-chat"/>
              : <>
                <PerfectScrollbar
                  className="user-chats widget-user-chat"
                  options={{
                    wheelPropagation: false,
                  }}
                  containerRef={((el): void => {
                    chatArea.current = el
                  })}
                >
                  <div className="chats">
                    {messages.map((message, idx) => (
                      <FeedbackMessageItem
                        key={idx}
                        message={message}
                        type={props.type}
                      />
                    ))}
                  </div>
                </PerfectScrollbar>

                <SendMessageFeedbackChatFooter
                  errors={errors}
                  onTyping={props.onTyping}
                  handleSendMessage={sendMessage}
                />
              </>
            }
          </>
        }
      </div>
    </div>
  )
}

export default FeedbackChat;
