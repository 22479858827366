import {useState, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Api, UnauthorizedException} from "../api";
import {FormError} from "../api/types";
import handleException from "../api/handleException";
import { refetchSession } from "../services/refetchSession";
import { sessionTokenSelector, isSessionRefetchingEnableSelector } from "../redux/session/selectors";
import {SessionTypes} from "../redux/session/types";
import {UserActionTypes} from "../redux/user/types";
import {history} from "../history";

function useFetch<Data>(resource: string, initialState: Data, lazy = false): {
  data: Data;
  fetchUrl(): Promise<void>;
  called: boolean;
  loading: boolean;
  errors: FormError[];
} {
  const [data, setData] = useState<Data>(initialState);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<FormError[]>([]);
  const [called, setCalled] = useState<boolean>(false);
  const dispatch = useDispatch();

  const token = useSelector(sessionTokenSelector);
  const isSessionRefetchingEnable = useSelector(isSessionRefetchingEnableSelector);

  const fetchUrl = useCallback(async () => {
    setErrors([])
    setCalled(true);
    setLoading(true);

    try {
      const response = await Api.get(resource, {}, token);
      const json: Data = await response.json();
      setData(json);

      if (isSessionRefetchingEnable && resource !== '/api/session') {
        await refetchSession(token, dispatch);
      }
    } catch (e) {
      // logout if token expired
      if (resource === '/api/session' && e instanceof UnauthorizedException) {
        dispatch({ type: SessionTypes.CLEAR });
        dispatch({ type: UserActionTypes.CLEAR });
        history.push("/login");
      }
      // e/o logout if token expired

      setErrors(handleException(e));
    }
    setLoading(false);
  }, [resource, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!lazy) fetchUrl();
  }, [fetchUrl, lazy]);

  return {data, fetchUrl, loading, errors, called};
}

export {useFetch};
