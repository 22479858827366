import React, {FC, FormEvent, useState} from "react"
import {Send} from "react-feather"
import {Button, Form, Input} from "reactstrap"
import ErrorMessage from "../../error/ErrorMessage";
import FormErrorMessage, {hasErrors} from "../../error/FormErrorMessage";
import {FormError} from "../../../api/types";

interface SendMessageFeedbackChatFooterProps {
  errors: FormError[];
  onTyping?: (isNotInputEmpty: boolean) => void;
  handleSendMessage(value: string): void;
}

const SendMessageFeedbackChatFooter: FC<SendMessageFeedbackChatFooterProps> = (
  {
    errors,
    onTyping,
    handleSendMessage,
  }
) => {
  const [inputValue, setInputValue] = useState("");

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setInputValue(value)
    if (onTyping) onTyping(!!value.trim())
  }

  const onMessageSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    setInputValue("");
    if (onTyping) onTyping(false);

    const trimmedInputValue = inputValue.trim()
    if (trimmedInputValue) {
      handleSendMessage(trimmedInputValue)
    }
  }

  return (
    <div className="chat-footer chat-app-form">
      <ErrorMessage errors={errors}/>

      <Form
        className="d-flex justify-content-around"
        onSubmit={onMessageSubmit}
      >
        <Input
          className="mr-50"
          invalid={hasErrors(errors, "msg")}
          placeholder="Napisz wiadomość..."
          value={inputValue}
          onChange={onInputChange}
        />
        <Button
          type="submit"
          className="btn-icon"
          color="success"
        >
          Wyślij
          <Send size={15} style={{marginLeft: 5}}/>
        </Button>
      </Form>
      <FormErrorMessage errors={errors} field="msg"/>
    </div>
  )
}

export default SendMessageFeedbackChatFooter;
