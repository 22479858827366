import React, {CSSProperties, FC} from "react";
import ScrollToTop from "react-scroll-up";
import { Button } from "reactstrap";
import { Heart, ArrowUp, Coffee } from "react-feather";
import classnames from "classnames";

interface FooterProps {
  footerType: "static"|"sticky"|"hidden";
  hideScrollToTop: boolean;
  className?: string;
  style?: CSSProperties;
}

const Footer: FC<FooterProps> = (props) => {
  const footerTypeArr = ["sticky", "static", "hidden"]
  return (
    <footer
      className={classnames("footer footer-light", props.className,
        {"footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
          "d-none": props.footerType === "hidden"},
      )}
      style={props.style}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          v {process.env.REACT_APP_VERSION}
        </span>
        <span className="float-md-right d-none d-md-block">
          <span className="align-middle">Made by Edgeteq with</span>
          <Heart className="text-danger mb-25" size={15} />
          <span className="align-middle"> &</span>
          <Coffee size={15} className="mb-25"/>
        </span>
      </p>
      {!props.hideScrollToTop ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
  )
}

export default Footer
