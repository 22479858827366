import { Reducer } from 'redux';
import { UserActionTypes } from './types';
import { UserState } from "../../types/users";
import Cookies from "js-cookie";

export const USER_KEY = "user";

const getInitialState = (): UserState => {
  const userJson = Cookies.get(USER_KEY);
  const user = userJson ? JSON.parse(userJson) : null;

  return {
    userId: user ? user.userId : null,
    active: user ? user.active : false,
    pulse: user ? user.pulse : false,
    role: user ? user.role : null,
    name: user ? user.name : null,
    surname: user ? user.surname : null,
    email: user ? user.email : null,
    teams: user ? user.teams : null,
    attributes: user ? user.attributes : null,
    googleId: user ? user.googleId : null,
  };
}

export const userReducer: Reducer<UserState> = (state = getInitialState(), action) => {
  switch (action.type) {
    case UserActionTypes.SET: {
      return { ...state, ...action.payload }
    }
    case UserActionTypes.CLEAR: {
      Cookies.remove(USER_KEY);
      return {...getInitialState()}
    }
    default: {
      return state
    }
  }
};

export default userReducer
