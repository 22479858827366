import {UserState} from "../../types/users";
import {StoreState} from "../storeTypes";
import {RoleType} from "../../types/roles";

export const userSelector: ((store: StoreState) => UserState) = (store) => {
  return store.user;
}

export const userFullNameSelector: ((store: StoreState) => string) = (store) => {
  return store.user.name && store.user.surname ? `${store.user.name} ${store.user.surname}` : '';
}

export const userRoleSelector: ((store: StoreState) => RoleType | null) = (store) => {
  return store.user.role;
}

export const userEmailSelector: ((store: StoreState) => string) = (store) => {
  return store.user.email || '';
}
