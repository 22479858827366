const headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
headers.append("X-Requested-With", "XMLHttpRequest");

const redirect = "manual";

const credentials = "same-origin";

export const defaultConfig: Partial<RequestInit> = {
  headers,
  redirect,
  credentials,
};
