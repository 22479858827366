import React, {FC, useEffect, useState} from "react"
import {Button} from "reactstrap"
import "moment/locale/pl";
import RatingFeedbackModal from "../authorFeedback/RatingFeedbackModal";
import {FeedbackStatusType} from "../../../types/feedback";

interface AuthorFeedbackChatHeaderProps {
  feedbackId: string;
  feedbackStatus?: FeedbackStatusType;
  feedbackRating: number | null
}

const AuthorFeedbackChatHeader: FC<AuthorFeedbackChatHeaderProps> = (
  {
    feedbackId,
    feedbackStatus,
    feedbackRating
  }
) => {
  const [openRatingModal, setOpenRatingModal] = useState<boolean>(false)
  const toggleOpenRatingModal = (): void => {
    setOpenRatingModal(!openRatingModal)
  }
  useEffect(() => {
    (feedbackStatus === FeedbackStatusType.CLOSED && feedbackRating === null)
      ? setOpenRatingModal(true)
      : setOpenRatingModal(false)
  },[ feedbackStatus, feedbackRating])

  return (
    <>
      <div className="alert alert-primary d-flex justify-content-between align-items-center mb-0 alert--close-feedback-info">
        { feedbackStatus !== FeedbackStatusType.CLOSED
          ? (
            <>
              <p>Czy uważasz wątek za zakończony?</p>

              <Button
                color="secondary"
                outline
                className={"px-1"}
                onClick={toggleOpenRatingModal}
              >
                Zakończ wątek
              </Button>
            </>
          ) : (
            <p>Wątek został zamknięty</p>
          )
        }
      </div>

      <RatingFeedbackModal
        openRatingModal={openRatingModal}
        toggleOpen={toggleOpenRatingModal}
        feedbackId={feedbackId}
        isClosed={feedbackStatus === FeedbackStatusType.CLOSED}
      />
    </>
  )
}

export default AuthorFeedbackChatHeader;
