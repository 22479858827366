const translateViolation: ((message: string) => string) = (message: string) => {
  return defaultViolationMessages[message] || message
}

const defaultViolationMessages: ({[key: string]: string}) = {
  'Invalid login or password.': 'Niepoprawny login lub hasło',
  'This value should not be blank.': 'To pole nie może być puste',
  'This value should not be null.': 'To pole nie może być puste',
  'This value is too short. It should have 1 character or more.': 'To pole nie może być puste',
  'This value is not valid.': 'Ta wartość jest niepoprawna',
  'This value is not a valid email address.': 'Niepoprawny adres e-mail',
  'This value is not a valid URL.': 'Niepoprawny adres URL',
  'This value should be a valid number.': 'Niepoprawna wartość liczbowa',
  'The two values should be equal.': 'Te wartości powinny być identyczne',
  'This is not a valid UUID.': 'Niepoprawna wartość identyfikatora',
  'The value you selected is not a valid choice.': 'Wybrana wartość jest niepoprawna',
  'One or more of the given values is invalid.': 'Jedna wartość bądź więcej jest niepoprawna',
  'This field was not expected.': 'To pole nie powinno występować',
  'This value is not a valid date.': 'Niepoprawna wartość daty',
  'This value is not a valid datetime.': 'Niepoprawna wartość daty',
  'The file could not be found.': 'Plik nie został znaleziony',
  'The file is not readable.': 'Nie można odczytać pliku',
  'The file could not be uploaded.': 'Nie można przesłać pliku',
  'This file is not a valid image.': 'Niepoprawny obraz',
  'This value is already used.': 'Ta wartość już występuje, proszę podać inną',
  'The size of the image could not be detected.': 'Nie można wykryć rozmiaru obrazu',
  "The file was only partially uploaded.": 'Plik został wczytany tylko częściowo',
  "This value is too short. It should have 8 characters or more.": 'Podana wartosć jest za krótka. Powinna mieć 8 lub więcej znaków',
  "Duplicated value.": 'Wartość zajęta.',
  "No file was uploaded.": 'Żaden plik nie został przesłany.',
  'Password should be at least 8 character long and contain at least one: uppercase letter, lowercase letter and digit': 'Hasło powinno posiadać przynajmniej 8 znaków ciągiem i zawierać przynajmniej jedną: cyfrę, wielką literę, małą literę',
  'Password expired' : 'Data ważności hasła wygasła.',
  'User password does not match provided password' : 'Wprowadzono błędne hasło',
  'Exam is closed or expired': 'Badanie wygasło lub zostało zakończone',
  'User is already member of another team': 'Ten użytkownik jest już członkiem innego zespołu',
  'User is not active': 'Ten użytkownik jest nieaktywny',
  'User is already assigned to this team': 'Użytkownik nie może być równocześnie członkiem i kierownikiem tego samego zespołu',
  'User with this email already exists.': 'Użytkownik o takim adresie e-mail już istnieje',
  'User is already assigned to another team': 'Użytkownik jest członkiem innego zespołu',
};

export default translateViolation;
