import React, { ComponentType } from "react"
import { shallowEqual } from "react-redux";
import VerticalLayout from "../../layouts/VerticalLayout"
import FullLayout from "../../layouts/FullpageLayout"
import HorizontalLayout from "../../layouts/HorizontalLayout"
import ExamLayout from "../../layouts/ExamLayout";
import themeConfig from "../../configs/themeConfig"
import AuthorFeedbackPage from "../../views/feedback/AuthorFeedbackPage";
import TopBarLayout from "../../layouts/TopBarLayout";

const layouts = {
  vertical: VerticalLayout as ComponentType,
  full: FullLayout as ComponentType,
  horizontal: HorizontalLayout as ComponentType,
  authorFeedback: AuthorFeedbackPage as ComponentType,
  exam: ExamLayout as ComponentType,
  topBar: TopBarLayout as ComponentType,
}

interface LayoutContext {
  state: LayoutState;
  fullLayout: ComponentType;
  verticalLayout: ComponentType;
  horizontalLayout: ComponentType;
  authorFeedbackLayout: ComponentType;
  examLayout: ComponentType;
  topBarLayout: ComponentType;
  switchLayout: (layout: string) => void;
}

const initialState: LayoutState = {
  activeLayout: themeConfig.layout,
  width: window.innerWidth,
  lastLayout: null,
  direction: themeConfig.direction
};

const initialContext: LayoutContext = {
  state: initialState,
  fullLayout: layouts["full"],
  verticalLayout: layouts["vertical"],
  horizontalLayout: layouts["horizontal"],
  authorFeedbackLayout: layouts["authorFeedback"],
  examLayout: layouts["exam"],
  topBarLayout: layouts["topBar"],
  switchLayout: () => null,
};

const ContextLayout = React.createContext<LayoutContext| null>(initialContext);

interface LayoutState {
  activeLayout: string;
  width: number;
  lastLayout: string | null;
  direction: string;
}

class Layout extends React.Component<Record<string, unknown>, LayoutState> {
  state = initialState;

  private updateWidth = (): void => {
    this.setState({
      width: window.innerWidth
    })
  }

  private handleWindowResize = (): void => {
    this.updateWidth()
    if (this.state.activeLayout === "horizontal" && this.state.width <= 1199) {
      this.setState({
        activeLayout: "vertical",
        lastLayout: "horizontal"
      })
    }

    if (this.state.lastLayout === "horizontal" && this.state.width >= 1199) {
      this.setState({
        activeLayout: "horizontal",
        lastLayout: "vertical"
      })
    }
  }

  public shouldComponentUpdate(
      nextProps: Readonly<Record<string, unknown>>,
      nextState: Readonly<LayoutState>,
      nextContext: any
  ): boolean {
    return (
        !shallowEqual(nextState, this.state) ||
        !shallowEqual(nextProps, this.props) ||
        !shallowEqual(nextContext, this.context)
    );
  }

  public componentDidMount = (): void => {
    if (this.state.activeLayout === "authorFeedback" || this.state.activeLayout === "exam") return

    window.addEventListener("resize", this.handleWindowResize);
    if (this.state.activeLayout === "horizontal" && this.state.width <= 1199) {
      this.setState({
        activeLayout: "vertical",
        lastLayout: "horizontal" // simplifies handleWindowResize computation
      })
    } else if (
      themeConfig.layout === "horizontal" &&
      this.state.width >= 1200
    ) {
      this.setState({
        activeLayout: "horizontal"
      })
    } else {
      this.setState({
        activeLayout: "vertical"
      })
    }
  }

  public render(): JSX.Element {
    const {children} = this.props
    return (
      <ContextLayout.Provider
        value={{
          ...initialContext,
          state: this.state,
          switchLayout: (layout): void => {
            this.setState({activeLayout: layout})
          },
        }}
      >
        {children}
      </ContextLayout.Provider>
    )
  }
}

export { Layout, ContextLayout }
