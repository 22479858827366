export enum RoleType {
  SUPER_ADMIN = "ROLE_SUPER_ADMIN",
  ADMIN = "ROLE_ADMIN",
  MANAGER = "ROLE_MANAGER",
  MEMBER = "ROLE_MEMBER",
}

export interface Role {
  label: string;
  role: RoleType;
  color: string;
}

export interface RolesData {
  items: Role[];
  total: number;
}
