import {
  BadRequestException,
  ForbiddenException,
  NotFoundException,
  UnauthorizedException,
  UnknownException,
} from "./index";
import {FormError} from "./types";
import {Exception} from "../types/api";

export default function handleException(exception: Exception | unknown): Array<FormError> {
   if (exception instanceof BadRequestException) {
    if (exception.errors.length === 1 && exception.errors[0].redirectUrl) {
      document.location.assign(exception.errors[0].redirectUrl);
    }
    return exception.errors;
  }
  if (exception instanceof NotFoundException) {
    return [{
      message: "Nie znaleziono",
    }];
  }
  if (exception instanceof ForbiddenException) {
    return [{
      message: "Brak dostępu",
    }];
  }
  if (exception instanceof UnauthorizedException) {
    return [{
      message: "Nieautoryzowany dostęp",
    }];
  }

  if (exception instanceof UnknownException) {
    return [{
      message: `Wystąpił nieznany błąd (${exception.status})`,
    }];
  }

  return [{
    message: 'Wystąpił nieznany błąd',
  }];
}
