import { Api } from "../api";
import { decodedTokenType } from "../types/token";
import jwtDecode from "jwt-decode";
import { SessionTypes } from "../redux/session/types";
import { calcRefetchDate } from "../utils/calcRefetchDate";
import { Dispatch } from "redux";

export const refetchSession = async (token: string | null, dispatch: Dispatch): Promise<void> => {
  const tokenResponse = await Api.get('/api/session', {}, token);
  const tokenJson: { token: string } = await tokenResponse.json();
  const decodedToken: decodedTokenType = jwtDecode(tokenJson.token);
  const refetchDate = calcRefetchDate(decodedToken.exp, decodedToken.iat)

  dispatch({
    type: SessionTypes.REFRESH,
    payload: {
      token: tokenJson.token,
      expirationDate: decodedToken.exp,
      refetchDate: refetchDate
    },
  });
}