import React, {useEffect} from "react"
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarUser from "./navbarUser/NavbarUser.tsx"
import NavbarMenuIcon from "./NavbarMenuIcon";
import {useDispatch, useSelector} from "react-redux";
import { organizationNameSelector, organizationLogoSelector } from "../../../redux/organizationSettings/selectors";
import {useFetch} from "../../../hooks/fetch";
import {OrganizationSettingsTypes} from "../../../redux/organizationSettings/types";
import {NavLink} from "react-router-dom";
import HorizontalMenu from "../menu/horizontal-menu/HorizontalMenu";
import useOrganizationContext from "../../../hooks/useOrganizationContext";

const ThemeNavbar = props => {
  const {loading, hasContext} = useOrganizationContext();
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  const organizationName = useSelector(organizationNameSelector)
  const organizationLogo = useSelector(organizationLogoSelector)
  const dispatch = useDispatch();

  const initialState = {
    name: organizationName,
    logo: organizationLogo || "" ,
  };

  const { data } = useFetch("/api/organization/current", initialState, !hasContext);

  useEffect(() => {
    dispatch({
      type: OrganizationSettingsTypes.SET,
      payload: data
    })
  }, [data, dispatch]);

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-white":
              props.navbarColor === "white" && props.navbarType !== "static",
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling
          }
        )}
        style={props.style}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex align-items-center"
              id="navbar-mobile"
            >
              {props.horizontal ? (
                <>
                  <NavLink
                    className="logo d-flex align-items-center"
                    to="/"
                  >
                    <div
                      className="brand-logo mr-50"
                      style={{ backgroundImage: `url(${organizationLogo})` }}
                    />

                    <h2 className="brand-text mb-0">
                      { organizationName }
                    </h2>
                  </NavLink>
                  <HorizontalMenu
                    toggleSidebarMenu={props.toggleSidebarMenu}
                    sidebarState={props.sidebarState}
                    sidebarHover={props.sidebarHover}
                    sidebarVisibility={props.sidebarVisibility}
                    visibilityState={props.visibilityState}
                    activePath={props.activePath}
                    currentLang={props.currentLang}
                    activeTheme={props.activeTheme}
                    collapsed={props.collapsed}
                    menuOpen={props.menuOpen}
                    navbarType={props.navbarType}
                  />
                </>
              )
                : !props.onlyTopBar && (
                  <NavbarMenuIcon
                    sidebarVisibility={props.sidebarVisibility}
                  />
              )}

              { !props.onlyTopBar &&
                <div className="bookmark-wrapper"/>
              }

              <NavLink
                className="logo d-flex align-items-center"
                to="/"
              >
                { (!loading && hasContext)
                  ? (
                    <>
                      { organizationLogo &&
                        <div
                          className="brand-logo mr-50 "
                          style={{ backgroundImage: `url(${organizationLogo})` }}
                        />
                      }

                      { organizationName &&
                        <b className="brand-text mb-0">
                          { organizationName }
                        </b>
                      }
                    </>
                  )
                  : (
                    <div className={"brand-component"}>
                      <div
                        className="brand-logo emplotick-logo-img"
                      />

                      <h2 className="brand-text mb-0">
                        emplotick
                      </h2>
                    </div>
                  )
                }

              </NavLink>

              <ul className="nav navbar-nav navbar-nav-user ml-auto">
                <NavbarUser/>
              </ul>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

export default ThemeNavbar
