import React, {FC} from "react"
import {useSelector} from "react-redux";
import {userSelector} from "../../../redux/user/selectors";
import UserAvatar from "./UserAvatar";
import {UserState} from "../../../types/users";

const LoggedInUserAvatar: FC = () => {
  const user: UserState = useSelector(userSelector);

  if (null == user?.role) {
    return null;
  }

  return (
    <UserAvatar
      name={user.name as string }
      surname={user.surname as string }
      userId={user.userId}
    />
  )
}

export default LoggedInUserAvatar
