import React from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import navigationConfig from "../../../../../configs/navigationConfig"
import SideMenuGroup from "./SideMenuGroup"
import { Badge } from "reactstrap"
import { ChevronRight } from "react-feather"
import { history } from "../../../../../history"
import {NavigationItemType} from "../../../../../types/navigation";

class SideMenuContent extends React.Component {
  constructor(props) {
    super(props)

    this.parentArr = []
    this.collapsedPath = null
    this.redirect403 = () => {
      history.push("/403")
    }
  }
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: []
  }

  handleGroupClick = (id, parent = null, type = "") => {
    let openGroup = this.state.activeGroups
    let activeGroup = this.state.currentActiveGroup
    let tempArr = this.state.tempArr
    // Active Group to apply sidebar-group-active class
    if (type === NavigationItemType.MENU_ITEM && parent === null) {
      activeGroup = []
      tempArr = []
    } else if (type === NavigationItemType.MENU_ITEM && parent !== null) {
      activeGroup = []
      if (tempArr.includes(parent)) {
        tempArr.splice(tempArr.indexOf(parent) + 1, tempArr.length)
      } else {
        tempArr = []
        tempArr.push(parent)
      }
      activeGroup = tempArr.slice(0)
    } else if (type === NavigationItemType.NESTED_MENU_PARENT_ITEM && parent === null) {
      tempArr = []
      tempArr.push(id)
    } else if (type === NavigationItemType.NESTED_MENU_PARENT_ITEM && parent !== null) {
      if (activeGroup.includes(parent)) {
        tempArr = activeGroup.slice(0)
      }
      if (tempArr.includes(id)) {
        // tempArr.splice(tempArr.indexOf(id), 1)
        tempArr.splice(tempArr.indexOf(id), tempArr.length)
      } else {
        tempArr.push(id)
      }
    } else {
      tempArr = []
    }

    if (type === NavigationItemType.NESTED_MENU_PARENT_ITEM) {
      // If open group does not include clicked group item
      if (!openGroup.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = openGroup.filter(function(obj) {
          return activeGroup.indexOf(obj) === -1
        })
        // Remove those unmatched items from open group
        if (temp.length > 0 && !openGroup.includes(parent)) {
          openGroup = openGroup.filter(function(obj) {
            return !temp.includes(obj)
          })
        }
        if (openGroup.includes(parent) && activeGroup.includes(parent)) {
          openGroup = activeGroup.slice(0)
        }
        // Add group item clicked in open group
        if (!openGroup.includes(id)) {
          openGroup.push(id)
        }
      } else {
        // If open group includes click group item, remove it from open group
        openGroup.splice(openGroup.indexOf(id), 1)
      }
    }
    if (type === NavigationItemType.MENU_ITEM) {
      openGroup = activeGroup.slice(0)
    }

    this.setState({
      activeGroups: openGroup,
      tempArr: tempArr,
      currentActiveGroup: activeGroup
    })
  }

  initRender = parentArr => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false
    })
  }

  componentDidMount() {
    this.initRender(this.parentArr[0] ? this.parentArr[0] : [])
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths)
      }

      this.initRender(
        this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []
      )
    }
  }

  render() {
    // Loop over sidebar items
    const menuItems = navigationConfig.map(item => {
      const CustomAnchorTag = item.type === "external-link" ? `a` : Link
      // checks if item has groupheader
      if (item.type === "groupHeader") {
        return (
          <li
            className="navigation-header"
            key={`group-header-${item.groupTitle}`}
          >
            <span>{item.groupTitle}</span>
          </li>
        )
      }

      let renderItem = (
        <li
          className={classnames("nav-item", {
            "has-sub": item.type === NavigationItemType.NESTED_MENU_PARENT_ITEM,
            open: this.state.activeGroups.includes(item.id),
            "sidebar-group-active": this.state.currentActiveGroup.includes(
              item.id
            ),
            hover: this.props.hoverIndex === item.id,
            disabled: item.disabled,
            active:
              item.id === "home"
                ? this.props.activeItemState === item.navLink
                : (this.props.activeItemState.startsWith(item?.navLink) &&
                  item.type === NavigationItemType.MENU_ITEM )|| (item.parentOf && item.parentOf.includes(this.props.activeItemState)),
          })}
          key={item.id}
          onClick={e => {
            e.stopPropagation()
            if (item.type === NavigationItemType.MENU_ITEM) {
              this.props.handleActiveItem(item.navLink)
              this.handleGroupClick(item.id, null, item.type)
              if(this.props.deviceWidth <= 1200 && item.type === NavigationItemType.MENU_ITEM){
                this.props.toggleMenu()
              }
            } else {
              this.handleGroupClick(item.id, null, item.type)
            }
          }}
        >
          <CustomAnchorTag
            to={item.filterBase ? item.filterBase : item.navLink && item.type === NavigationItemType.MENU_ITEM ? item.navLink :  ""}
            href={item.type === "external-link" ? item.navLink : ""}
            className={`d-flex ${
              item.badgeText
                ? "justify-content-between"
                : "justify-content-start"
            }`}
            onMouseEnter={() => {
              this.props.handleSidebarMouseEnter(item.id)
            }}
            onMouseLeave={() => {
              this.props.handleSidebarMouseEnter(item.id)
            }}
            key={item.id}
            onClick={e => {
              return item.type === NavigationItemType.NESTED_MENU_PARENT_ITEM ? e.preventDefault() : ""
            }}
            target={item.newTab ? "_blank" : undefined}
          >
            <div className="menu-text">
              {item.icon}
              <span className="menu-item menu-title">{item.title}</span>
            </div>

            {item.badge ? (
              <div className="menu-badge">
                <Badge color={item.badge} className="mr-1" pill>
                  {item.badgeText}
                </Badge>
              </div>
            ) : (
              ""
            )}
            {item.type === NavigationItemType.NESTED_MENU_PARENT_ITEM ? (
              <ChevronRight className="menu-toggle-icon" size={13} />
            ) : (
              ""
            )}
          </CustomAnchorTag>
          {item.type === NavigationItemType.NESTED_MENU_PARENT_ITEM ? (
            <SideMenuGroup
              group={item}
              handleGroupClick={this.handleGroupClick}
              activeGroup={this.state.activeGroups}
              handleActiveItem={this.props.handleActiveItem}
              activeItemState={this.props.activeItemState}
              handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
              activePath={this.props.activePath}
              hoverIndex={this.props.hoverIndex}
              initRender={this.initRender}
              parentArr={this.parentArr}
              triggerActive={undefined}
              currentActiveGroup={this.state.currentActiveGroup}
              permission={this.props.permission}
              currentUser={this.props.currentUser}
              redirect403={this.redirect403}
              collapsedMenuPaths={this.props.collapsedMenuPaths}
              toggleMenu={this.props.toggleMenu}
              deviceWidth={this.props.deviceWidth}
            />
          ) : (
            ""
          )}
        </li>
      )

      if (
        item.navLink &&
        item.collapsed !== undefined &&
        item.collapsed === true
      ) {
        this.collapsedPath = item.navLink
        this.props.collapsedMenuPaths(item.navLink)
      }

      if (
        item.type === "external-link" ||
        ([NavigationItemType.NESTED_MENU_PARENT_ITEM, NavigationItemType.MENU_ITEM].includes(item.type)
          && item.permissions
          && item.permissions.includes(this.props.currentUser)
        ) || item.permissions === undefined
      ) {
        return renderItem
      } else if (
        (!item.type || item.type === NavigationItemType.MENU_ITEM) &&
        item.navLink === this.props.activePath
        && !item.permissions.includes(this.props.currentUser)
      ) {
        return this.redirect403()
      }
      return null;
    })

    return <React.Fragment>{menuItems}</React.Fragment>
  }
}
export default SideMenuContent
