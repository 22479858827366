import React, {FC} from "react";
import { Button, ButtonProps, Modal, ModalHeader, ModalBody, ModalFooter, ModalProps } from "reactstrap";

interface ModalComponentProps extends ModalProps {
  title: string;
  open: boolean;
  actionButtonText?: string;
  variant?: string;
  actionButtonProps?: ButtonProps;
  closeButtonText?: string;
  closeButtonProps?: ButtonProps;
  disabled?: boolean;
  onClose?(): void;
  handleFormSubmit?(): void;
}

const ModalComponent: FC<ModalComponentProps> = (
  {
    title,
    open,
    onClose,
    actionButtonText,
    actionButtonProps,
    closeButtonText,
    closeButtonProps,
    variant = "primary",
    disabled = false,
    handleFormSubmit,
    children
  }
) => {
  const modalContent = (
    <>
      <ModalHeader
        toggle={onClose}
        className={`bg-${variant}`}
      >
        { title }
      </ModalHeader>

      <ModalBody>
        { children }
      </ModalBody>

      <ModalFooter>
        <Button
          color="secondary"
          outline
          disabled={disabled}
          onClick={onClose}
          {...closeButtonProps}
        >
          {closeButtonText || "Zamknij"}
        </Button>

        { (actionButtonText && actionButtonProps) &&
          <Button
            color={"primary"}
            disabled={disabled}
            {...actionButtonProps}
          >
            { actionButtonText }
          </Button>
        }
      </ModalFooter>
    </>
  )

  return (
    <>
      <Modal
        isOpen={open}
        toggle={onClose}
        className="modal-dialog-centered"
        backdrop="static"
        unmountOnClose
      >
        { handleFormSubmit
          ? (
            <form onSubmit={handleFormSubmit}>
              {modalContent}
            </form>
          )
          : modalContent
        }
      </Modal>
    </>
  );
};

export default ModalComponent;
