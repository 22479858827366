import {FormError} from "../api/types";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import handleException from "../api/handleException";
import {Api} from "../api";
import {refetchSession} from "../services/refetchSession";
import {sessionTokenSelector, isSessionRefetchingEnableSelector} from "../redux/session/selectors";

enum Action {
    CREATE = 'POST',
    UPDATE = 'PUT',
    DELETE = 'DELETE',
}

export const useMutation: any = (
  successCallback?: (response?: any) => void,
  errorCallback?: () => void,
  getResponse?: boolean
) => {
  const [isSaving, setSaving] = useState(false);
  const [errors, setErrors] = useState<FormError[]>([]);
  const dispatch = useDispatch();

  const token = useSelector(sessionTokenSelector);
  const isSessionRefetchingEnable = useSelector(isSessionRefetchingEnableSelector);

  const clearErrors = (): void => {
    setErrors([]);
  }

  const save = async <Data>(action: Action, resource: string, data: Data): Promise<void> => {
    clearErrors();
    setSaving(true);

    let response = null;

    try {
      if (action === Action.CREATE){
        if (getResponse) {
          response = await Api.post(resource, data, token);
        } else {
          await Api.post(resource, data, token);
        }
      } else if (action === Action.UPDATE){
        if (getResponse) {
          response = await Api.put(resource, data, token);
        } else {
          await Api.put(resource, data, token);
        }
      } else if (action === Action.DELETE){
        if (getResponse) {
          response = await Api.remove(resource, data, token);
        } else {
          await Api.remove(resource, data, token);
        }
      }

      if (isSessionRefetchingEnable) {
        await refetchSession(token, dispatch);
      }

      if (successCallback) {
        let json;
        if (response) {
          json = await response.json();
        }

        json ? successCallback(json) : successCallback();
      }
    } catch (e) {
      const exceptionErrors = handleException(e);
      setErrors(exceptionErrors);
      if (errorCallback) {
        errorCallback();
      }
    }
    setSaving(false);
  };

  const update = async <Data>(resource: string, data: Data): Promise<void> => {
    await save(Action.UPDATE, resource, data);
  };

  const create = async <Data>(resource: string, data: Data): Promise<void> => {
    await save(Action.CREATE, resource, data);
  };

  const remove = async <Data>(resource: string, data: Data): Promise<void> => {
    await save(Action.DELETE, resource, data);
  };

  return {update, create, remove, isSaving, errors, clearErrors};
};
