import React, { FC } from 'react';
import '../../../assets/scss/components/app-loader.scss';
import classnames from "classnames";

interface SpinnerProps {
  className?: string;
}

const Spinner: FC<SpinnerProps> = ({className}) => {
  return <div className={classnames("fallback-spinner", className)}>
    <div className="loading component-loader">
      <div className="effect-1 effects"/>
      <div className="effect-2 effects"/>
      <div className="effect-3 effects"/>
    </div>
  </div>
};

export default Spinner;
