import { EventSourcePolyfill, NativeEventSource } from "event-source-polyfill";
import { useEffect, useMemo } from "react";

const EventSource = NativeEventSource || EventSourcePolyfill;
const baseUrl = document.location.origin;

interface Arguments<T>{
  topics: string[];
  onMessage: (event: T) => void;
}

const useEventSource = <T>(
  {
    topics,
    onMessage,
  }: Arguments<T>
): void => {
  const url: string | null = useMemo(() => {
    if (!topics.length) return null;

    const url = new URL(`/.well-known/mercure`, baseUrl);

    topics
      .map((topic) => new URL(topic, baseUrl))
      .map((topic) => topic.toString())
      .forEach((topic) => {
        url.searchParams.append("topic", topic);
      });

    return url.toString();
  }, [topics]);

  useEffect(() => {
    const es = new EventSource(url);
    es.addEventListener("message", (e: MessageEvent) => {
      const data = JSON.parse(e.data);
      onMessage(data);
    });

    return () => {
      es.close();
    };
  }, [url, onMessage]);
};

export default useEventSource;
