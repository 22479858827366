import {User} from "./users";
import {BaseParams} from "./api";

export interface ParamsFilter extends BaseParams {
  sort: Array<"status_asc" | "status_desc" | "date_asc" | "date_desc" | "last_message_date_desc" | "last_message_date_asc">;
  contentPart?: string;
}

export interface Feedback {
  feedbackId: string; // uuid
  createdAt: string; // date string
  anonymous: boolean;
  user: User | null; // anonymous ? null : User
  messages: FeedbackMessage[];
  status: FeedbackStatusType;
  lastMessageDate: string //date string
  rating: number | null
}

interface FeedbackMessage { // from GET request api/feedbacks/${feedbackId}
  author: User | null;
  date: string; // date string
  type: FeedbackMessageType;
  content: string;
}

export interface NewFeedbackMessageEvent { // from MERCURE new message event api/feedbacks/${feedbackId}
  author: User | null;
  date: string; // date string
  message: string;
  type: FeedbackMessageType;
}

export interface ChatFeedbackMessage { // common type used in FeedbackMessageItem
  isSent: boolean;
  msg: string;
  type: FeedbackMessageType;
  date?: string;
  author: User | null;
}

export interface StatusChangeEventType {
  feedbackId: string;
  status: FeedbackStatusType;
}

export interface Description {
  content: string;
}

export interface DescriptionStatus {
  open: boolean;
  edit: boolean;
}

export enum FeedbackMessageType {
  AUTHOR = "author",
  MANAGER = "manager",
}

export enum FeedbackStatusType {
  PING = "ping", // last message is from FeedbackMessageType.AUTHOR
  PONG = "pong", // last message is from FeedbackMessageType.MANAGER
  CLOSED = "closed", // closed feedback
}
