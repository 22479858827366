import React, { FC } from 'react'
import { DropdownItem } from "reactstrap";
import * as Icon from "react-feather";
import { history } from "../../../../../history";
import { useDispatch } from "react-redux";
import { SessionTypes } from "../../../../../redux/session/types";
import { UserActionTypes } from "../../../../../redux/user/types";

const LogoutNavbarItem: FC = () => {
  const dispatch = useDispatch();

  const logout = (): void => {
    history.push("/login")

    dispatch({ type: SessionTypes.CLEAR });
    dispatch({ type: UserActionTypes.CLEAR })
  }

  return (
    <DropdownItem
      tag="a"
      href="#"
      onClick={logout}
    >
      <Icon.Power size={14} className="mr-50" />

      <span className="align-middle">
        Wyloguj się
      </span>
    </DropdownItem>
  )
}

export default LogoutNavbarItem