import {defaultConfig} from "./defaultConfig";
import {handleResponse} from "./handleResponse";

export default async function remove(resource: string, params: Record<string, any>, token: string | null = null): Promise<Response> {
  const method = "DELETE";
  const body = JSON.stringify(params);

  const headers = new Headers(defaultConfig.headers);
  if (token !== null) {
    headers.set("X-Authorization", `Bearer ${token}`);
  }

  const response = await fetch(resource, {...defaultConfig, method, body, headers});

  return handleResponse(response);
}
