import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingRoles, rolesFetchError, setRoles } from "../redux/roles/actions";
import { isRolesLoading, rolesSelector } from "../redux/roles/selectors";
import { Role } from "../types/roles";
import { useFetch } from "./fetch";

let initialized = false;

const useRoleList = () => {
  const loading = useSelector(isRolesLoading);
  const roles = useSelector(rolesSelector);
  const dispatch = useDispatch();
  const {data, fetchUrl} = useFetch<{items: Role[]}|null>("/api/roles", null, true);

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      dispatch(loadingRoles());
      fetchUrl().catch(() => {
        initialized = false;
        dispatch(rolesFetchError())
      });
    }
  }, [fetchUrl, dispatch]);

  useEffect(() => {
    if (data?.items) {
      dispatch(setRoles(data.items))
    }
  }, [data, dispatch]);

  return {roles, initialized, loading};
};

export default useRoleList;
