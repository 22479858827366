import React, {FC, useCallback, useEffect, useState} from "react";
import { Info } from "react-feather";
import {useHistory, useParams} from "react-router-dom";
import { Card, CardBody } from "reactstrap"
import classnames from "classnames";
import FeedbackChat from "../../components/feedback/@common/FeedbackChat";
import themeConfig from "../../configs/themeConfig";
import { useFetch } from "../../hooks/fetch";
import Footer from "../../layouts/components/footer/Footer";
import { User } from "../../types/users";
import { useDispatch } from "react-redux";
import {SessionTypes} from "../../redux/session/types";
import {UserActionTypes} from "../../redux/user/types";

import chatImg1 from "../../assets/img/pages/author-feedback/chat-icon-1.svg"
import chatImg2 from "../../assets/img/pages/author-feedback/chat-icon-2.svg"
import chatImg3 from "../../assets/img/pages/author-feedback/chat-icon-3.svg"
import useEventSource from "../../hooks/useEventSource";
import {FeedbackMessageType, FeedbackStatusType, StatusChangeEventType} from "../../types/feedback";
import {RoutePath} from "../../router/routes/Routes.types";

interface AuthorFeedbackPageParams {
  feedbackId: string;
}

const AuthorFeedbackPage: FC = () => {
  const {feedbackId} = useParams<AuthorFeedbackPageParams>();
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const [isValidFeedbackId, setIsValidFeedbackId] = useState<boolean>(false);
  const [organizationName, setOrganizationName] = useState<string>("")
  const [organizationLogo, setOrganizationLogo] = useState<string>("")
  const [feedbackStatus, setFeedbackStatus] = useState<FeedbackStatusType>()

  const dispatch = useDispatch();
  const history = useHistory();

  const [areChatImagesAnimating, setAreChatImagesAnimating] = useState<boolean>(false)

  useEffect(() => {
    dispatch({ type: SessionTypes.CLEAR });
    dispatch({ type: UserActionTypes.CLEAR });
  }, [dispatch]);

  const { data } = useFetch("/api/organization/current", { name: '', logo: '' });

  useEffect(() => {
    setOrganizationName(data.name)
    setOrganizationLogo(data.logo)
  }, [data]);

  const onLoad = (author: User| null, isValidFeedbackId: boolean): void => {
    setAnonymous(!author)
    setIsValidFeedbackId(isValidFeedbackId)

    if (!isValidFeedbackId) {
      history.push(RoutePath.ERROR_404)
    }
  }

  const chatImages = [chatImg1, chatImg2, chatImg3]

  const onTyping = (isNotInputEmpty: boolean) => {
    setAreChatImagesAnimating(isNotInputEmpty)
  }

  const statusChangeEventHandler = useCallback((event: StatusChangeEventType) => {
    if(feedbackId=== event.feedbackId) {
      setFeedbackStatus(event.status)
    }
  }, [setFeedbackStatus, feedbackId]);

  useEventSource({
    topics: ['/api/feedbacks'],
    onMessage: statusChangeEventHandler,
  });

  return (
    <div className="author-feedback-page w-100">
      {
        chatImages.map((chatImg, idx) => (
          <img
            src={chatImg}
            key={chatImg}
            alt=""
            className={classnames(`chat-img chat-img--${idx + 1}`, {
              "animating": areChatImagesAnimating
            })}
          />
        ))
      }

      <div className="container">
        <div className="organization-info">
          <div
            className="logo"
            style={{ backgroundImage: `url(${organizationLogo})` }}
          />

          <h2 className="brand-text mb-0">
            { organizationName }
          </h2>
        </div>

        <Card className="card">
          <CardBody>
            <div className="chat-application chat-application--author chat-widget position-relative">
              <div className="chat-app-window">
                <div className="active-chat d-block">
                  <div className="chat_navbar">
                    <header className="chat_header d-flex justify-content-center align-items-center">
                      <h1 className="h4 mt-0 mb-4 px-1 text-center">
                        Informacja zwrotna
                      </h1>
                    </header>
                  </div>
                </div>

                <FeedbackChat
                  feedbackId={feedbackId}
                  feedbackStatus={feedbackStatus}
                  type={FeedbackMessageType.AUTHOR}
                  onLoad={onLoad}
                  onTyping={onTyping}
                />

                { anonymous && isValidFeedbackId
                  ? <small className="text-info text-bold-600">
                    <Info />
                    {' '}
                    Twoja tożsamość została ukryta. Rozmowa jest w pełni anonimowa.
                  </small>
                  : null
                }
              </div>
            </div>
          </CardBody>
        </Card>

        <Footer
          footerType="sticky"
          hideScrollToTop={themeConfig.hideScrollToTop}
        />
      </div>
    </div>
  );
};

export default AuthorFeedbackPage;
