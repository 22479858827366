import { FC } from "react";
import React from "react";
import useRoleList from "../../hooks/useRoleList";

interface RoleNameProps {
  children: string;
  colors?: boolean;
}

const RoleName: FC<RoleNameProps> = (
  {
    children,
    colors,
  }
) => {
  const {roles} = useRoleList()
  const role = roles?.find((r) => r.role === children);

  if (!colors) {
    return <>{role?.label}</>;
  }

  return <span style={{color: role?.color}}>{role?.label}</span>;
}

export default RoleName;
