// when create new route
// add route to Router.js and navigationConfig.tsx

export enum RoutePath {
  HOMEPAGE = "/",
  EXAM = "/exam/:examId",
  TEST_EXAM = "/test-exam",
  SETTINGS = "/settings",
  ALERTS = "/alerts/:pulseId?",
  ALERTS_DEFAULT = "/alerts",
  PULSE_QUESTIONS_PREVIEW = "/pulse-questions-preview",
  USERS_LIST = "/users",
  USER_CREATE = "/user-create",
  USER_PROFILE_SETTINGS = "/user/profile/settings",
  USER_EDIT = "/user/:userId",
  TEAMS_LIST = "/teams",
  TEAM_CREATE = "/team-create",
  TEAM_EDIT = "/team/:teamId",
  ORGANIZATION_STRUCTURE = "/structure",
  STATS = "/stats/:pulseId?",
  STATS_DEFAULT = "/stats",
  TEAM_STATS = "/team-stats/:teamId",
  INDICATOR_STATS = "/indicator-stats/:indicatorCode",
  INDICATOR_X_TEAM_STATS = "/indicator-per-team-stats/:teamId/:indicatorCode",
  FEEDBACKS_LIST = "/feedbacks/:feedbackId?",
  FEEDBACKS_LIST_DEFAULT = "/feedbacks",
  AUTHOR_FEEDBACK = "/author-feedback/:feedbackId",
  SEND_FEEDBACK = "/send-feedback",
  WELCOME_ADMIN = "/welcome",
  WELCOME_MEMBER = "/welcome-member",

  LOGIN = "/login",
  GOOGLE_OAUTH = "/oauth/google",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/password-reset/:token",
  EXPIRED_PASSWORD = "/expired-password",
  ERROR_403 = "/403",
  ERROR_404 = "/404",
  ERROR_500 = "/500",
}
