import {SessionState} from "./types";
import {StoreState} from "../storeTypes";

export const isAuthenticatedSelector: ((store: StoreState) => boolean) = (store) => {
  return store.session.token !== null;
}

export const sessionTokenSelector: ((store: StoreState) => string | null) = (store) => {
  return store.session.token;
}

export const sessionExpirationDateSelector: ((store: StoreState) => Date | null) = (store) => {
  return store.session.expirationDate;
}

export const sessionSelector: ((store: StoreState) => SessionState) = (store) => {
  return store.session;
}

export const isSessionRefetchingEnableSelector: ((store: StoreState) => boolean | null) = (store) => {
  if (!store.session.expirationDate || !store.session.refetchDate) {
    return null
  }

  const currentSecTime = +new Date() / 1000
  return currentSecTime > store.session.refetchDate
}
