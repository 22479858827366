import React from "react"
import themeConfig from "../configs/themeConfig"
import classnames from "classnames"
import Footer from "./components/footer/Footer";

const FullPageLayout = (props) => {
  return (
    <div
      className={classnames(
        "full-layout wrapper bg-full-screen-image blank-page dark-layout",
        {
          "layout-dark": themeConfig.layoutDark,
          "bg-full-screen-image--send-feedback": props.match.path === "/send-feedback"
        }
      )}
    >
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="flexbox-container">
              <main className="main w-100 m-auto">
                {props.children}
              </main>

              <Footer
                className="w-100 ml-0"
                footerType={themeConfig.footerType}
                hideScrollToTop={themeConfig.hideScrollToTop}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullPageLayout
