import React, { FC, useState } from 'react'
import { useMutation } from "../../../hooks/mutation";
import ModalComponent from "../../@common/modal/ModalComponent";
import StarRatingComponent from 'react-star-rating-component';
import {Star} from 'react-feather'

interface RatingFeedbackModalProps {
  openRatingModal: boolean
  toggleOpen: () => void
  feedbackId: string
  isClosed: boolean
}

const RatingFeedbackModal:FC<RatingFeedbackModalProps> = ({openRatingModal, toggleOpen, feedbackId, isClosed}) => {
  const { update, remove } = useMutation()
  const [rating, setRating] = useState<number>(0)

  const handleCloseFeedback = async () => {
    !isClosed && await remove(`/api/feedbacks/${feedbackId}`)
    toggleOpen()
  }

  const handleRating = async (rate: number) => {
    setRating(rate)
    await handleCloseFeedback()
    await update( `/api/feedbacks/${feedbackId}/rate`, {rating: rate})
  }

  return (
    <ModalComponent
      title="Zakończenie wątku"
      open={openRatingModal}
      className="d-flex justify-content-center"
      onClose={handleCloseFeedback}
    >
      <div className="text-center">
        { isClosed
          ? <p className='font-weight-bold'>Twój wątek został zamknięty.</p>
          : <p className='font-weight-bold'>Dziękujemy, wątek został zamknięty!</p>
        }

        <p>
          <em>Prosimy o ocenę satysfakcji po kontakcie w sprawie rozwiązania wątku.</em>
        </p>

        <StarRatingComponent
          name='rating'
          value={rating}
          onStarClick={handleRating}
          renderStarIcon={() => <Star/>}
        />
      </div>
    </ModalComponent>
  )
}

export default RatingFeedbackModal
