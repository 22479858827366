// CONST from _variables.scss
const primaryColor = '#78A7C3'
const secondaryColor = '#C39478'
const secondaryLightColor = '#e8e1cf'
const successColor = '#78C3B2'
const infoColor = '#00cfe8'
const warningColor = '#ff9f43'
const backgroundColor = '#FAF5F2'
// e/o CONST from _variables.scss

// diff colors
const noDiffColor = '#e8ded2'

const minWorseResultColor = "#F5AFAF"
const mediumWorseResultColor = "#F48E91"
const maxWorseResultColor = "#EE5E63"

const minBetterResultColor = "#AEDFF0"
const mediumBetterResultColor = "#70C5E2"
const maxBetterResultColor = "#34ACDA"
// e/o diff colors

export {
  noDiffColor,
  maxWorseResultColor,
  minWorseResultColor,
  mediumWorseResultColor,
  minBetterResultColor,
  maxBetterResultColor,
  mediumBetterResultColor,
  primaryColor,
  secondaryColor,
  secondaryLightColor,
  successColor,
  infoColor,
  warningColor,
  backgroundColor
};
