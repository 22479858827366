import React, { FC } from "react";
import {primaryColor, secondaryColor, successColor} from "../../../utils/colors/colorsConstVariables";
import useOrganizationContext from "../../../hooks/useOrganizationContext";

interface UserAvatarProps {
  userId?: string | null;
  name: string;
  surname: string;
}

const UserAvatar: FC<UserAvatarProps> = ({userId, name, surname}) => {
  const {hasContext} = useOrganizationContext()
  const background = hasContext ? getUserAvatarColor(userId!) : successColor;

  return (
    <div className="avatar" style={{
      background
    }}>
      <span className="avatar-content" style={{ paddingTop: "3px" }}>
        {name?.charAt(0).toUpperCase() || "S"}
        {surname?.charAt(0).toUpperCase() || "A"}
      </span>
    </div>
  );
};

export const getUserAvatarColor = (userId: string) => {
  const colorsArr: string[] = [
    primaryColor,
    secondaryColor,
    '#8FBC8B',
    '#9C4F4F',
    '#C37889',
    '#C378B6',
    "#3E9B9F",
    "#5F9EA0",
    '#226365',
    '#27566B',
  ];
  const firstDigit = userId.match(/\d/)

  return firstDigit ? colorsArr[Number(firstDigit[0])] : successColor
};

export const renderUserAvatar = (ctx: CanvasRenderingContext2D) =>(x: number, y: number, size: number) => {
  return (userId: string, text: string, bgColor: string, color: string) => {
    ctx.beginPath();
    ctx.fillStyle = bgColor;
    ctx.ellipse(x, y, size, size, 0, 0, Math.PI * 2);
    ctx.fill();

    if (color !== bgColor) {
      ctx.beginPath();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = color;
      ctx.font = `${size*0.8}px Montserrat, Helvetica, Arial, serif`;
      ctx.fillText(text, x, y);
    }
  };
}

export default UserAvatar;
