import React, {FC, useEffect, useState} from "react";
import {useFetch} from "../hooks/fetch";
import Footer from "./components/footer/Footer";
import themeConfig from "../configs/themeConfig";

const ExamLayout: FC = ({children}) => {
  const [organizationName, setOrganizationName] = useState<string>("")
  const [organizationLogo, setOrganizationLogo] = useState<string>("")

  const { data } = useFetch("/api/organization/current", { name: '', logo: '' });

  useEffect(() => {
    setOrganizationName(data.name)
    setOrganizationLogo(data.logo)
  }, [data]);

  return (
    <div className="exam-page w-100">
      <div className="container">
        <div className="organization-info">
          <div
            className="logo"
            style={{ backgroundImage: `url(${organizationLogo})` }}
          />

          <h2 className="brand-text mb-0">
            { organizationName }
          </h2>
        </div>

        { children }

        <Footer
          footerType="sticky"
          hideScrollToTop={themeConfig.hideScrollToTop}
        />
      </div>
    </div>
  );
};

export default ExamLayout;
