import {defaultConfig} from "./defaultConfig";
import {createUrl} from "../utils/createUrl";
import {handleResponse} from "./handleResponse";

export default async function get(resource: string, query: Record<string, any> = {}, token: string | null = null): Promise<Response> {
  const method = "GET";
  const url = createUrl(resource, query);

  const headers = new Headers(defaultConfig.headers);
  if (token !== null) {
    headers.set("X-Authorization", `Bearer ${token}`);
  }

  const response = await fetch(url, {...defaultConfig, method, headers});
  return handleResponse(response);
}
