import { Reducer } from 'redux';
import { SessionState, SessionTypes } from "./types";
import Cookies from "js-cookie";

export const TOKEN_KEY = "token";

const getInitialState = (): SessionState => {
  const token = Cookies.get(TOKEN_KEY);

  return {
    token: token ?? null,
    expirationDate: null,
    refetchDate: null
  };
}

const sessionReducer: Reducer<SessionState> = (state = getInitialState(), action) => {
  switch (action.type) {
    case SessionTypes.SET: {
      if (state.token) {
        Cookies.set(TOKEN_KEY, state.token);
      }
      return { ...state, ...action.payload }
    }
    case SessionTypes.REFRESH: {
      Cookies.set(TOKEN_KEY, action.payload.token);
      return { ...state, ...action.payload }
    }
    case SessionTypes.CLEAR: {
      Cookies.remove(TOKEN_KEY);
      return {...getInitialState()}
    }
    default: {
      return state
    }
  }
}

export default sessionReducer
