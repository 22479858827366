import {RoleType} from "./roles";
import {RoutePath} from "../router/routes/Routes.types";

export interface NavigationItem {
  id: string;
  title: string;
  type: NavigationItemType;
  icon?: JSX.Element; // for MENU_ITEM and NESTED_MENU_PARENT_ITEM
  navLink?: RoutePath; // not required for NESTED_MENU_PARENT_ITEM
  permissions: RoleType[];
  children?: NavigationItem[]; // for NESTED_MENU_PARENT_ITEM
}

export enum NavigationItemType {
  MENU_ITEM = "item",
  NESTED_MENU_PARENT_ITEM = "parent",
  // @ts-ignore
  NOT_VISIBLE_IN_MENU = null,
}
