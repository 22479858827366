import {defaultConfig} from "./defaultConfig";
import {handleResponse} from "./handleResponse";

export default async function patch(resource: string, body: FormData, token: string | null = null): Promise<Response> {
  const method = "PATCH";

  const headers = new Headers(defaultConfig.headers);
  headers.delete("Content-Type");

  if (token !== null) {
    headers.set("X-Authorization", `Bearer ${token}`);
  }

  const response = await fetch(resource, {
    ...defaultConfig,
    method,
    body,
    headers,
  });

  return handleResponse(response);
}
