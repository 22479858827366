import { Role } from "../../types/roles";
import { RolesType } from "./types";

export const setRoles = (roles: Role[]) => ({
  type: RolesType.SET,
  payload: {roles}
});

export const loadingRoles = () => ({
  type: RolesType.LOADING,
});

export const rolesFetchError = () => ({
  type: RolesType.ERROR,
});
