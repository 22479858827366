import React, { FC } from "react"
// import * as Icon from "react-feather";
// import { Link } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  // DropdownItem,
} from "reactstrap"
import { useSelector } from "react-redux";
import RoleName from "../../../../components/users/RoleName";
import { userFullNameSelector, userRoleSelector } from "../../../../redux/user/selectors";
import LogoutNavbarItem from "./dropdownItems/LogoutNavbarItem";
import LoggedInUserAvatar from "../../../../components/@common/avatar/LoggedInUserAvatar";
import useOrganizationContext from "../../../../hooks/useOrganizationContext";

const NavbarUser: FC = () => {
  const {hasContext} = useOrganizationContext()
  const userFullName: string = useSelector(userFullNameSelector)
  const roleName = useSelector(userRoleSelector);

  return (
    <>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">
              { userFullName }
            </span>

            {roleName &&(
              <span className="user-status">
                <RoleName>{roleName}</RoleName>
              </span>
            )}

            { !hasContext && (
              <span className="user-status">
                Superadmin
              </span>
            )}
          </div>

          <span data-tour="user">
            <LoggedInUserAvatar/>
          </span>
        </DropdownToggle>

        <DropdownMenu right style={{minWidth: "200px"}}>
          {/*{ hasContext &&*/}
          {/*  <DropdownItem*/}
          {/*    tag={Link}*/}
          {/*    to="/user/profile/settings"*/}
          {/*  >*/}
          {/*    <Icon.User size={14} className="mr-50"/>*/}
          {/*    <span className="align-middle">*/}
          {/*    Ustawienia konta*/}
          {/*  </span>*/}
          {/*  </DropdownItem>*/}
          {/*}*/}

          <LogoutNavbarItem/>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

export default NavbarUser
