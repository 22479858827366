import {
  ForbiddenException,
  NotFoundException,
  UnauthorizedException,
  UnknownException,
  BadRequestException,
} from "./index";
import {SessionTypes} from "../redux/session/types";
import {history} from "../history";
import {store} from "../redux/store";

export async function handleResponse(response: Response): Promise<Response> {
  switch (response.status) {
    case 200:
      return response;
    case 201:
      return response;
    case 400:
      throw new BadRequestException(await response.json());
    case 401: {
      store.dispatch({ type: SessionTypes.CLEAR })
      history.push("/login");
      throw new UnauthorizedException();
    }
    case 403:
      throw new ForbiddenException();
    case 404:
      throw new NotFoundException();
    default:
      throw new UnknownException(response);
  }
}
