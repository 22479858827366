import React, {FC} from "react";
import {FormError} from "../../api/types";
import {FormFeedback} from "reactstrap";
import classnames from "classnames";
import translateViolation from "./MessageTranslation";
import checkOrphans from "../../utils/checkOrphans";

interface ErrorMessageProps {
  errors: Array<FormError>;
  field?: string;
}

export const hasErrors = (errors: Array<FormError>, field?: string): boolean => {
  return errors.some(error => error.property_path === field);
};

const FormErrorMessage: FC<ErrorMessageProps> = ({errors, field}) => {
  const currentErrors = errors.filter(error => {
    if (!field) {
      return !error.property_path;
    }

    return error.property_path === field;
  });

  return (
    <FormFeedback className={classnames({"d-block": currentErrors.length})}>
      {currentErrors.map((error,idx) => (
        <div key={idx}>
          <span
            dangerouslySetInnerHTML={{ __html: checkOrphans(translateViolation(error.message))}}
          />
        </div>
      ))}
    </FormFeedback>
  )
};

export default FormErrorMessage;
