export enum SessionTypes {
    SET = "session/set",
    SAVE = "session/save",
    REFRESH = "session/refresh",
    CLEAR = "session/clear",
}

export type SessionState = {
    token: string | null;
    expirationDate: Date | null;
    refetchDate: number | null;
}
