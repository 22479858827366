import React from "react"
import * as Icon from "react-feather"
import {RoleType} from "../types/roles";
import {NavigationItem, NavigationItemType} from "../types/navigation";
import {RoutePath} from "../router/routes/Routes.types";

const visibleInNavigationItems: NavigationItem[] = [
  {
    id: "home",
    title: "Strona główna",
    type: NavigationItemType.MENU_ITEM,
    navLink: RoutePath.HOMEPAGE,
    icon: <Icon.Home size={20} />,
    permissions: [RoleType.ADMIN, RoleType.MANAGER]
  },
  {
    id: "exam",
    title: "Badanie - Puls",
    type: NavigationItemType.NOT_VISIBLE_IN_MENU,
    navLink: RoutePath.EXAM,
    permissions: [RoleType.ADMIN, RoleType.MANAGER, RoleType.MEMBER]
  },
  {
    id: "stats",
    title: "Analiza",
    type: NavigationItemType.MENU_ITEM,
    icon: <Icon.Activity size={20} />,
    navLink: RoutePath.STATS_DEFAULT,
    permissions: [RoleType.ADMIN, RoleType.MANAGER],
  },
  {
    id: "feedbacks",
    title: "Feedback",
    type: NavigationItemType.MENU_ITEM,
    icon: <Icon.MessageSquare size={20} />,
    navLink: RoutePath.FEEDBACKS_LIST_DEFAULT,
    permissions: [RoleType.ADMIN, RoleType.MANAGER],
  },
  {
    id: "alerts",
    title: "Alerty",
    type: NavigationItemType.MENU_ITEM,
    icon: <Icon.Bell size={20} />,
    navLink: RoutePath.ALERTS_DEFAULT,
    permissions: [RoleType.ADMIN, RoleType.MANAGER],
  },
  {
    id: "structure",
    title: "Struktura",
    type: NavigationItemType.NESTED_MENU_PARENT_ITEM,
    icon: <Icon.Package size={20} />,
    permissions: [RoleType.ADMIN, RoleType.MANAGER],
    children: [
      {
        id: "users",
        title: "Użytkownicy",
        type: NavigationItemType.MENU_ITEM,
        // icon: <Icon.Users size={14} />,
        icon: <Icon.Circle size={12} />,
        navLink: RoutePath.USERS_LIST,
        permissions: [RoleType.ADMIN]
      },
      {
        id: "teams",
        title: "Zespoły",
        type: NavigationItemType.MENU_ITEM,
        // icon: <Icon.Grid size={14} />,
        icon: <Icon.Circle size={12} />,
        navLink: RoutePath.TEAMS_LIST,
        permissions: [RoleType.ADMIN]
      },
      {
        id: "organization_tree",
        title: "Struktura organizacji",
        type: NavigationItemType.MENU_ITEM,
        // icon: <Icon.Hexagon size={14} />,
        icon: <Icon.Circle size={12} />,
        navLink: RoutePath.ORGANIZATION_STRUCTURE,
        permissions: [RoleType.ADMIN, RoleType.MANAGER],
      }
    ]
  },
  {
    id: "settings",
    title: "Ustawienia",
    type: NavigationItemType.MENU_ITEM,
    icon: <Icon.Settings size={20} />,
    navLink: RoutePath.SETTINGS,
    permissions: [RoleType.ADMIN],
  },
  {
    id: "pulse-questions",
    title: "Pytania pulsu",
    type: NavigationItemType.MENU_ITEM,
    icon: <Icon.Clipboard size={20} />,
    navLink: RoutePath.PULSE_QUESTIONS_PREVIEW,
    permissions: [RoleType.ADMIN],
  },
]

const navigationConfig: NavigationItem[] = [
  ...visibleInNavigationItems,
  // other routes with defined permission
  {
    id: "userCreate",
    title: "Tworzenie użytkownika",
    type: NavigationItemType.NOT_VISIBLE_IN_MENU,
    navLink: RoutePath.USER_CREATE,
    permissions: [RoleType.ADMIN]
  },
  {
    id: "userEdit",
    title: "Edycja danych użytkownika",
    type: NavigationItemType.NOT_VISIBLE_IN_MENU,
    navLink: RoutePath.USER_EDIT,
    permissions: [RoleType.ADMIN]
  },
  {
    id: "teamCreate",
    title: "Tworzenie zespołu",
    type: NavigationItemType.NOT_VISIBLE_IN_MENU,
    navLink: RoutePath.TEAM_CREATE,
    permissions: [RoleType.ADMIN]
  },
  {
    id: "teamEdit",
    title: "Edycja zespołu",
    type: NavigationItemType.NOT_VISIBLE_IN_MENU,
    navLink: RoutePath.TEAM_EDIT,
    permissions: [RoleType.ADMIN]
  },
  {
    id: "teamStats",
    title: "Analiza zespołu",
    type: NavigationItemType.NOT_VISIBLE_IN_MENU,
    navLink: RoutePath.TEAM_STATS,
    permissions: [RoleType.ADMIN, RoleType.MANAGER]
  },
  {
    id: "indicatorStats",
    title: "Analiza wskaźnika",
    type: NavigationItemType.NOT_VISIBLE_IN_MENU,
    navLink: RoutePath.INDICATOR_STATS,
    permissions: [RoleType.ADMIN, RoleType.MANAGER]
  },
  {
    id: "indicatorPerTeamStats",
    title: "Analiza wskaźnika dla zespołu",
    type: NavigationItemType.NOT_VISIBLE_IN_MENU,
    navLink: RoutePath.INDICATOR_X_TEAM_STATS,
    permissions: [RoleType.ADMIN, RoleType.MANAGER]
  },
  // e/o other routes with defined permission
]

export default navigationConfig
