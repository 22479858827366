import get from "./get";
import post from "./post";
import put from "./put";
import patchFiles from "./patchFiles";
import remove from "./remove";
import {Exception} from "../types/api";
import {FormError} from "./types";

export class NotFoundException extends Error implements Exception {
  constructor() {
    super("Not found.");
  }
}

export class ForbiddenException extends Error implements Exception {
  constructor(){
    super("Action Forbidden");
  }
}

export class UnauthorizedException extends Error implements Exception {
  constructor() {
    super("Unauthorized");
  }
}

export class BadRequestException extends Error implements Exception {
  public readonly errors: FormError[];

  constructor({errors}: {errors: FormError[]}) {
    super("BadRequest");
    this.errors = errors;
  }
}

export class UnknownException extends Error implements Exception {
  public readonly status: number;
  public readonly url: string;

  constructor(error: {status: number; url: string}) {
    super("Unknown");
    this.status = error.status;
    this.url = error.url;
  }
}

export const Api = {get, post, put, patchFiles, remove};
export default Api;
