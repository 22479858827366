import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store } from "./redux/store"
import Spinner from "./components/@vuexy/spinner/Spinner"
import "./index.scss"
import TagManager from "react-gtm-module"

const LazyApp = lazy(() => import("./App"))
const isProduction = process.env.NODE_ENV === "production"

if (isProduction) {
  const tagManagerArgs = {
    gtmId: "GTM-M3PT7W8"
  }

  TagManager.initialize(tagManagerArgs)
}

// configureDatabase()
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner className="vh-100"/>}>
      <Layout>
        <LazyApp />
      </Layout>
    </Suspense>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
