import React from "react"
import { Button } from "reactstrap";
import { Disc, X, Circle } from "react-feather"
import classnames from "classnames"

const SidebarHeader = (
  {
    toggleSidebarMenu,
    activeTheme,
    collapsed,
    toggle,
    sidebarVisibility,
    menuShadow
  }) => {
  return (
    <div className="navbar-header mb-2">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item mr-auto">
          <Button color="none" className="navbar-brand p-0" onClick={() => {
            sidebarVisibility()
            // history.push("/");
          }}>
            <div
              className="brand-logo emplotick-logo-img"
            />

            <h2 className="brand-text mb-0">
              emplotick
            </h2>
          </Button>
        </li>

        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle">
            {collapsed === false ? (
              <Disc
                onClick={() => {
                  toggleSidebarMenu(true)
                  toggle()
                }}
                className={classnames(
                  "toggle-icon icon-x d-none d-xl-block font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark"
                  }
                )}
                size={20}
                data-tour="toggle-icon"
              />
            ) : (
              <Circle
                onClick={() => {
                  toggleSidebarMenu(false)
                  toggle()
                }}
                className={classnames(
                  "toggle-icon icon-x d-none d-xl-block font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark"
                  }
                )}
                size={20}
              />
            )}
            <X
              onClick={sidebarVisibility}
              className={classnames(
                "toggle-icon icon-x d-block d-xl-none font-medium-4",
                {
                  "text-primary": activeTheme === "primary",
                  "text-success": activeTheme === "success",
                  "text-danger": activeTheme === "danger",
                  "text-info": activeTheme === "info",
                  "text-warning": activeTheme === "warning",
                  "text-dark": activeTheme === "dark"
                }
              )}
              size={20}
            />
          </div>
        </li>
      </ul>

      <div
        className={classnames("shadow-bottom", {
          "d-none": menuShadow === false
        })}
      />
    </div>
  )
}

export default SidebarHeader
